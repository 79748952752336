/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import SiteMeta from '../../components/siteMeta';
import PhotosGrid from '../../components/photos';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I made a trip to Mexico City for the first time in my life. Overall amazing city but I unfortunately suffered from really bad\n", React.createElement(_components.a, {
    href: "https://www.wikiwand.com/en/Traveler's_diarrhea"
  }, "traveler’s diarrhea"), " after taking one gulp\nof water at a coffee shop…"), "\n", React.createElement(_components.p, null, "Lesson learned. Would still highly commend going and I plan to go back at some point."), "\n", React.createElement(PhotosGrid, {
    directory: "mexico"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
