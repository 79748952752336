import React, { useCallback, useState, memo } from 'react'
import PropTypes from 'prop-types'
import Layout from './Layout'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
// import 'react-medium-image-zoom/dist/styles.css'
import Lightbox from 'react-image-lightbox'
import PhotoAlbum from 'react-photo-album'
import 'react-image-lightbox/style.css'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'

const photosQuery = graphql`
  query AllPhotos {
    allFile(
      sort: { fields: [name], order: ASC }
      filter: { sourceInstanceName: { eq: "photos" } }
    ) {
      edges {
        node {
          name
          id
          relativeDirectory
          childImageSharp {
            gatsbyImageData
            original {
              src
            }
            resize(width: 474, height: 316, rotate: 0) {
              src
            }
          }
        }
      }
    }
  }
`

const PhotosGrid = memo(({ photos, onClick }) => {
  console.log('photos: ', photos)
  const albumPhotos = photos.map(({ node: { childImageSharp: img } }) => ({
    src: img.resize.src,
    width: 474, // img.resize.width,
    height: 316, // img.resize.height
  }))
  console.log('ablum photos: ', albumPhotos)
  return <PhotoAlbum layout="columns" columns={4} photos={albumPhotos} onClick={onClick} />
})

PhotosGrid.displayName = 'Photos2'
PhotosGrid.propTypes = {
  photos: PropTypes.array,
  onClick: PropTypes.func,
}

// next step is to pass the photo index from above and when you click on a photo in the handler call setState on the
// the given photo index.
const PhotosLightBox = memo(({ photos, photoIndex, onNext, onPrevious, onClose, isOpen }) => {
  const currImage = photos[photoIndex]?.node?.childImageSharp?.original?.src
  const previousImage =
    photos[(photoIndex + photos.length - 1) % photos.length]?.node?.childImageSharp?.original?.src
  const nextImage = photos[(photoIndex + 1) % photos.length]?.node?.childImageSharp?.original?.src
  return (
    <div>
      <>
        {isOpen && (
          <Lightbox
            mainSrc={currImage}
            nextSrc={nextImage}
            prevSrc={previousImage}
            onCloseRequest={onClose}
            onMoveNextRequest={onNext}
            onMovePrevRequest={onPrevious}
          />
        )}
      </>
    </div>
  )
})

PhotosLightBox.displayName = 'PhotosLightBox'

const PhotosContainer = ({ directory = './' }) => {
  const data = useStaticQuery(photosQuery)
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const photos =
    directory !== './'
      ? data.allFile.edges
      : data.allFile.edges.filter((e) => e.node.relativeDirectory === directory)

  const onNext = useCallback(() => {
    setPhotoIndex((i) => (i + 1) % photos.length)
  }, [])
  const onPrevious = useCallback(() => {
    setPhotoIndex((i) => (i + photos.length - 1) % photos.length)
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])
  // const data ={allFile: {edges: []}}

  const onPhotoClick = useCallback((e, photo, index) => {
    console.log('clicked photo: ', photo, ' index: ', index)
    setPhotoIndex(index)
    setIsOpen(true)
  }, [])

  return (
    <div>
      <PhotosGrid photos={data.allFile.edges} onClick={onPhotoClick} />
      <PhotosLightBox
        photos={data.allFile.edges}
        isOpen={isOpen}
        onClose={onClose}
        photoIndex={photoIndex}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </div>
  )
}

export default PhotosContainer
